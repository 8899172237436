<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" x1="69.91" y1="257.69" x2="69.91" y2="39.27" xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient" x1="69.9" y1="92.65" x2="69.9" y2="44.16" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" x1="69.9" y1="146.03" x2="69.9" y2="97.54" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" x1="69.9" y1="252.8" x2="69.9" y2="204.31" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-4" data-name="glass-gradient" x1="69.9" y1="199.42" x2="69.9" y2="150.92" xlink:href="#glass-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V26">
      <polyline id="inox" 
                v-if="showInox"
                data-name="inox" 
                class="cls-4" 
                :points="`${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 39.27} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 257.69} ${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 257.69} ${doorLeftWidth1 + 83.86} ${doorTopHeight1 + 39.27} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 39.27}`"/>
      <polyline filter="url(#inset-shadow)" id="glass" 
                class="cls-5" 
                :points="`${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 44.16} ${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 92.65} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 92.65} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 44.16} ${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 44.16}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2" 
                data-name="glass" 
                class="cls-6" 
                :points="`${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 97.54} ${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 97.54} ${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 146.03} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 146.03} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 97.54}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3" 
                data-name="glass" 
                class="cls-7" 
                :points="`${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 204.31} ${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 252.8} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 252.8} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 204.31} ${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 204.31}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-4" 
                data-name="glass" 
                class="cls-8" 
                :points="`${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 199.42} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 199.42} ${doorLeftWidth1 + 78.98} ${doorTopHeight1 + 150.92} ${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 150.92} ${doorLeftWidth1 + 60.83} ${doorTopHeight1 + 199.42}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
